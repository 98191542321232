<template>
  <div>
    <b-row class="d-flex align-items-center">
      <b-col>
        <b-form-group
          label="Danışman"
          label-for="users"
        >
          <v-select
            id="users"
            v-model="filterData.id_com_consultant"
            placeholder="Danışman"
            :options="users"
            label="name"
            :reduce="item => item.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Aylar"
          label-for="month"
        >
          <v-select
            id="month"
            v-model="filterData.month"
            placeholder="Aylar"
            :options="months"
            label="title"
            :reduce="item => item.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Yıllar"
          label-for="year"
        >
          <cleave
            id="year"
            v-model="filterData.year"
            class="form-control"
            :raw="false"
            :options="options.years"
            placeholder="Yıllar"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :reduce="item => item.id"
            @input="filterAction"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="filterData.month !== null || filterData.id_com_consultant !== null || filterData.id_com_brand !== null"
        cols="auto"
      >
        <b-button
          variant="warning"
          @click="resetAction"
        >
          <FeatherIcon icon="XCircleIcon" />
          Sıfırla
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'

export default {
  name: 'PublicFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BButton,
    vSelect,
    Cleave,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionFilter: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    resetFilter: {
      type: Function,
    },
  },
  data() {
    return {
      keyword: null,
      detailSearch: false,
      filterData: {
        month: null,
        year: null,
        id_com_consultant: null,
        id_com_brand: null,
      },
      options: {
        years: {
          blocks: [4],
          numericOnly: true,
        },
      },
      months: [
        {
          id: 1,
          title: 'Ocak',
        },
        {
          id: 2,
          title: 'Şubat',
        },
        {
          id: 3,
          title: 'Mart',
        },
        {
          id: 4,
          title: 'Nisan',
        },
        {
          id: 5,
          title: 'Mayıs',
        },
        {
          id: 6,
          title: 'Haziran',
        },
        {
          id: 7,
          title: 'Temmuz',
        },
        {
          id: 8,
          title: 'Ağustos',
        },
        {
          id: 9,
          title: 'Eylül',
        },
        {
          id: 10,
          title: 'Ekim',
        },
        {
          id: 11,
          title: 'Kasım',
        },
        {
          id: 12,
          title: 'Aralık',
        },
      ],
    }
  },
  computed: {
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.getBrands()
    this.getUsers()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
        where: {
          'com_brand.salestatus': 1,
        },
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        where: {
          'com_user.status': 1,
          'com_user.id_com_department': 2,
        },
      })
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter(this.filterData)
    },
    resetAction() {
      this.filterData = {
        month: null,
        year: null,
        id_com_consultant: null,
        id_com_brand: null,
      }
      this.resetFilter()
    },
  },
}
</script>
