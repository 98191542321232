<template>
  <b-row>
    <b-col cols="12">
      <b-card>
        <public-filter
          :action-filter="filterData"
          :reset-filter="resetSearch"
        />
      </b-card>
    </b-col>
    <b-col cols="12">
      <b-col
        cols="auto"
        class="text-right mb-2"
      >
        <b-button
          to="consultant_goals/add"
          variant="primary"
        >
          <FeatherIcon icon="PlusIcon" />
          Danışman Hedef Ekle
        </b-button>
      </b-col>
      <b-card no-body>
        <b-table
          class="font-small-3"
          responsive="sm"
          :fields="fields"
          :items="consultantGoals"
          striped
          hover
        >
          <template #cell(username)="data">
            {{ data.item.username }}
            <div v-if="data.item.modified">
              <small class="text-warning"><b>Güncelleme: </b>{{ moment(data.item.modified).format('DD.MM.YYYY') }}</small>
            </div>
          </template>
          <template #cell(brand)="data">
            <div>{{ data.item.brand }}</div>
          </template>
          <template #cell(month)="data">
            <div>{{ moment(data.item.month).format('MMMM') }}</div>
          </template>
          <template #cell(control)="data">
            <b-button
              variant="primary"
              size="sm"
              :to="'/consultant_goals/edit/' + data.item.id"
            >
              Güncelle
            </b-button>
          </template>
        </b-table>
        <b-card-footer>
          <b-pagination
            v-model="currentPage"
            :total-rows="consultantGoalsCount"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-card-footer>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BCardFooter, BPagination, BButton,
} from 'bootstrap-vue'
import PublicFilter from '@/views/Sales/Consultant_goals/Index/PublicFilter.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BCardFooter,
    BPagination,
    BButton,
    PublicFilter,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'username',
          label: 'Danışman Adı Soyadı',
        },
        {
          key: 'brand',
          label: 'Marka',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'month',
          label: 'Ay',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'year',
          label: 'Yıl',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'goal',
          label: 'Hedef',
          thStyle: { verticalAlign: 'middle' },
        },
        {
          key: 'control',
          label: 'Güncelle',
        },
      ],
      dataQuery: {
        select: [
          'com_consultant_goals.id AS id',
          'com_user.name AS username',
          'com_brand.name AS brand',
          'com_consultant_goals.month AS month',
          'com_consultant_goals.year AS year',
          'com_consultant_goals.goal AS goal',
          'com_consultant_goals.modified AS modified',
        ],
        limit: 10,
        start: 0,
        order_by: ['com_consultant_goals.id', 'DESC'],
      },
    }
  },
  computed: {
    consultantGoals() {
      return this.$store.getters['consultantGoals/getConsultantGoals']
    },
    consultantGoalsCount() {
      return this.$store.getters['consultantGoals/getConsultantGoalsCount']
    },
  },
  watch: {
    currentPage(val) {
      const page = (val - 1) * 10
      this.pagination(page)
    },
  },
  created() {
    this.getConsultantGoals()
  },
  methods: {
    getConsultantGoals() {
      this.$store.dispatch('consultantGoals/consultantGoalsList', this.dataQuery)
    },
    pagination(page) {
      this.dataQuery.start = page
      if (this.dataQuery.start === page) {
        this.getConsultantGoals()
      }
    },
    resetSearch() {
      this.dataQuery.or_like = {}
      this.dataQuery.where = {}
      this.getConsultantGoals()
    },
    filterData(params) {
      const where = {}
      if (params.id_com_consultant) {
        where['com_consultant_goals.id_com_consultant'] = params.id_com_consultant
      }
      if (params.id_com_brand) {
        where['com_consultant_goals.id_com_brand'] = params.id_com_brand
      }
      if (params.month) {
        where['com_consultant_goals.month'] = params.month
      }
      if (params.year) {
        where['com_consultant_goals.year'] = params.year
      }
      this.dataQuery.where = where
      this.getConsultantGoals()
    },
  },
}
</script>

<style scoped>

</style>
